"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import Slider from "@/components/Atoms/Slider";
import TypographyMontserrat, {
  Heading,
} from "@/components/Atoms/TypographyMontserrat";
import { RightArrowIcon } from "../../../public/Theme";
import { settings, PRE_ORDER } from "./constants";
import { setBackwardCursor, setForwardCursor } from "@/lib/Products/actions";
import PropTypes from "prop-types";
import { sendGTMEvent } from "@next/third-parties/google";

const ShoppingProductCard = ({
  images,
  link,
  name,
  categories,
  brandName,
  backwardCursor,
  forwardCursor,
  price,
  currencyCode,
  onClick,
}) => {
  const imagesCollection = images?.length > 3 ? images?.slice(0, 3) : images;
  // const isNewJessieCollection = categories?.find((id) => id === 171); For Pre-Order Slug on Product cards

  const handleClick = () => {
    if (typeof window !== "undefined") {
      if (forwardCursor) setForwardCursor(forwardCursor);
      else if (backwardCursor) setBackwardCursor(backwardCursor);
    }
    if (onClick) onClick();
  };
  return (
    <Link
      onClick={handleClick}
      href={`/products${link}`}
      key={name}
      prefetch
      scroll
      title={`View details for ${name}`}
      className="product-card max-w-[400px] group cursor-pointer"
    >
      <div className="w-full relative shop-card-dots">
        {imagesCollection?.length === 1 ? (
          <div className="relative min-h-[300px] lg:min-h-[550px] max-h-[300px] lg:max-h-[550px]">
            <Image
              priority
              quality={80}
              src={
                imagesCollection?.[0]?.node?.url || imagesCollection?.[0]?.url
              }
              alt={name}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              className="object-contain"
            />
          </div>
        ) : (
          <Slider
            className="overflow-hidden"
            {...settings}
            afterChange={() => {
              sendGTMEvent({
                event: "ImageInteractions",
                interaction_type: "Image Swiped",
                interaction_item: `Product Card: ${name}`,
              });
            }}
          >
            {imagesCollection?.map((image, index) => (
              <div
                className="relative min-h-[300px] lg:min-h-[550px] max-h-[300px] lg:max-h-[550px]"
                key={index}
              >
                <Image
                  priority={index === 0}
                  quality={75}
                  src={image?.node?.url || image?.url}
                  alt={name}
                  fill
                  sizes="(max-width:768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  className="w-full h-auto object-contain"
                  loading={index === 0 ? "eager" : "lazy"}
                />
              </div>
            ))}
          </Slider>
        )}
        {/* For Pre-Order Slug on Product cards
        {isNewJessieCollection && (
          <div className=" absolute border p-2 bottom-0 bg-Primary_Default bg-opacity-20 border-black ">
            <TypographyMontserrat
              align="left"
              customStyle="text-xs sm:text-sm"
              fontWeight="bold"
              text={PRE_ORDER}
            />
          </div>
        )} */}
      </div>
      <div className="mt-2 sm:mt-3 flex justify-between">
        <div className="flex flex-col gap-y-1 sm:gap-y-2 w-full relative">
          <Heading
            decoration={"underline"}
            fontWeight={"bold"}
            customStyle={"text-sm sm:text-base"}
            text={brandName}
            color={"New_Primary_Default"}
          />
          <div className="flex justify-between items-center">
            <TypographyMontserrat
              align="left"
              customStyle="text-xs sm:text-base uppercase"
              fontWeight="light"
              text={name}
            />
            <Image
              width={14}
              height={14}
              src={RightArrowIcon}
              alt="right arrow icon"
            />
          </div>
          {price && (
            <div>
              <TypographyMontserrat
                align="left"
                customStyle="text-xs sm:text-sm"
                fontWeight="normal"
                text={`${currencyCode} ${price}`}
              />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

ShoppingProductCard.propTypes = {
  images: PropTypes?.array?.isRequired,
  link: PropTypes?.string?.isRequired,
  name: PropTypes?.string?.isRequired,
  brandName: PropTypes?.string?.isRequired,
  backwardCursor: PropTypes?.string,
  forwardCursor: PropTypes?.string,
  price: PropTypes?.string?.isRequired,
  currencyCode: PropTypes?.string?.isRequired,
};

export default ShoppingProductCard;
