export const settings = {
  dots: true,
  slidesToShow: 1,
  speed: 800,
  autoplaySpeed: 4000,
  cssEase: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  arrows: false,
};

export const AED_TEXT = "AED";
export const PRE_ORDER = "PRE-ORDER";
