"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import TypographyMontserrat from "@/components/Atoms/TypographyMontserrat";
import { RightArrowIcon } from "../../../public/Theme";
import { setBackwardCursor, setForwardCursor } from "@/lib/Products/actions";
import PropTypes from "prop-types";

const SearchBarShoppingProductCard = ({
  images,
  link,
  name,
  backwardCursor,
  forwardCursor,
  price,
  currencyCode,
  onClick,
}) => {
  const imagesCollection = images?.length > 3 ? images?.slice(0, 3) : images;
  const handleClick = () => {
    if (typeof window !== "undefined") {
      if (forwardCursor) setForwardCursor(forwardCursor);
      else if (backwardCursor) setBackwardCursor(backwardCursor);
    }
    onClick();
  };

  return (
    <Link
      onClick={handleClick}
      href={`/products${link}`}
      key={name}
      prefetch
      scroll
      title={`View details for ${name}`}
      className="product-card max-w-[300px] group cursor-pointer"
    >
      <div className="w-full relative shop-card-dots">
        <div className="relative">
          <Image
            priority
            quality={80}
            src={imagesCollection?.[0]?.node?.url || imagesCollection?.[0]?.url}
            alt={name}
            sizes="100vw"
            height={0}
            width={0}
            className="w-full min-[375px]:h-[230px] sm:h-[280px] object-contain aspect-[107/140]"
          />
        </div>
      </div>
      <div className="mt-2 sm:mt-3 flex justify-between">
        <div className="flex flex-col gap-y-1 sm:gap-y-2 w-full relative">
          <div className="flex justify-between items-center">
            <TypographyMontserrat
              align="left"
              customStyle="text-xs sm:text-base uppercase text-truncate"
              fontWeight="light"
              text={name?.length > 15 ? name?.slice(0, 15) + "..." : name}
            />
            <Image
              width={14}
              height={14}
              src={RightArrowIcon}
              alt="right arrow icon"
            />
          </div>
          {price && (
            <div>
              <TypographyMontserrat
                align="left"
                customStyle="text-xs sm:text-sm"
                fontWeight="normal"
                text={`${currencyCode} ${price}`}
              />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

SearchBarShoppingProductCard.propTypes = {
  images: PropTypes?.array?.isRequired,
  link: PropTypes?.string?.isRequired,
  name: PropTypes?.string?.isRequired,
  backwardCursor: PropTypes?.string,
  forwardCursor: PropTypes?.string,
  price: PropTypes?.string?.isRequired,
  currencyCode: PropTypes?.string?.isRequired,
};

export default SearchBarShoppingProductCard;
